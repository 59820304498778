body {
    background-color: #f2f2f2;
    font-family: Arial, sans-serif;
}

.container {
    max-width: 400px;
    position: relative;
    top: 180px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.title {
    text-align: center;
    background: -webkit-linear-gradient(rgb(20, 192, 20), rgb(94, 91, 91));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    top: 160px;
}

.titleHeader {
    text-align: center;
    background: -webkit-linear-gradient(rgb(20, 192, 20), rgb(94, 91, 91));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 30px;
}

form {
    display: flex;
    flex-direction: column;
}


input[type="text"], input[type="password"] {
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.enter {
    background-color: #4CAF50;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.enter:hover {
    background-color: #3e8e41;
}

header {
    position: relative;
  }


span {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
}

span p {
  margin: 0 10px 0 0;
}

span img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}


.imageLillte{
  width: 300px;
}

.loader {
  font-size: 24px;
  color: #333;
  text-align: center;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  padding: 0 20px;
  height: 80px;
}

.navbar-link {
  color: #333;
  font-size: 18px;
  text-decoration: none;
  margin-right: 20px;
  transition: color 0.2s ease-in-out;
}

.navbar-link:hover {
  color: #ddd;
}

.containerPefilEdit {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 45px;
}

.containerPefilEdit label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.containerPefilEdit input,
.containerPefilEdit textarea {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.ButtonSave {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #4CAF50;
  color: white;
}

.containerSearch {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.inputNameMusic {
  font-size: 18px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 20px;
}

.search {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.search:hover {
  background-color: #3e8e41;
}

.search:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.errorMessage {
  color: red;
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.containerFavorite{
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}



.containerMusic {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


.ResultsMusic {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.SectionOfMusic {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.linkSearc {
  width: 30%;
  margin: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: #000;
}

.linkSearc:hover {
  box-shadow: 0 20px 16px 0 rgba(0, 0, 0, 0.2);
}

.artistName {
  font-size: 1.0rem;
  text-decoration: none;
}

.NameOfMusic{
  font-size: 1.2rem;
  margin-top: 20px;
  font-weight: bold;
}

.containerCard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.artistNameMusicMusicCard {
  font-size: 2rem;
  margin-top: 20px;
}

.collectionNameMusicMusicCard {
  font-size: 1.5rem;
  margin-top: 10px;
}

.audioContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.audioContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.audioPlayer {
  display: flex;
  align-items: center;
}

.buttonFavorite {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.buttonFavorite:hover {
  background-color: #3e8e41;
}

.WordFavorite {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #4CAF50;
  text-align: center;
}

.buttonRemove {
  background-color: #dd1b1b;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.buttonRemove:hover {
  background-color: darkred;
}

@media (max-width: 600px) {
  span {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
  }

  span p {
    font-size: 14px;
    margin: 2;
  }

  span img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
}
